import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import Img from "gatsby-image";

class ExplainerAlt extends React.Component {

  render() {
    return (
      <StaticQuery
        query={graphql`
  query {
    imageOne: file(relativePath: { eq: "spaceman-front.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
        } 
      }
    }
}
`}
        render={data => (
    <section id="explainer-section-alt">
    <div className="inner special explainer">
    <div className="about-box">
    <div className="about-image">
    <Img fluid={data.imageOne.childImageSharp.fluid} alt="" />
    </div>
    <div className="about-explainer alt">
        <header className="major special">
            <h2>Going beyond</h2>
        </header>
        <p className="displayText">My mission is very simple. <b>To innovate</b> and <b>to create value</b>. <b>To help you aim for the stars, and then take you there</b>. I hope to look back at my career, knowing that I left a solid body of work behind me.<br/><br/>Having <b>over 8 years of experience</b> with web technologies - and having seen many mismanaged WordPress installations at this point, I know that the way many people have their digital ecosystems set up today, ultimately end up being detrimental to themselves in the long run. <br/><br/><b>I'm here to change that.</b></p>
        <ul className="actions lowkey">
            <li><Link to="/contact" className="button next">Get in touch</Link></li>
        </ul>

        </div>
        </div>
    </div>
</section>
        )}
      />
    );
  }
}

export default ExplainerAlt;