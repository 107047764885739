import React from "react";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import BannerAbout from "../components/BannerAbout";
import ExplainerAbout from "../components/ExplainerAbout";
import ExplainerAlt from "../components/ExplainerAlt";

const LandingSEO = (props) => {
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://brandinavian.com/#organization",
        name: "Brandinavian",
        url: "https://brandinavian.com/",
        sameAs: [
          "https://www.facebook.com/brandinavian/",
          "https://www.instagram.com/brandinavian/",
          "https://www.linkedin.com/company/brandinavian/",
          "https://twitter.com/brandinavian",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://brandinavian.com/#logo",
          inLanguage: "en-US",
          url:
          "https://cdn.brandinavian.com/wp-content/uploads/2020/09/brandinavian-logo.png",
          width: 1112,
          height: 116,
          caption: "Brandinavian | Modern Web Development",
        },
        image: { "@id": "https://brandinavian.com/#logo" },
      },
      {
        "@type": "WebSite",
        "@id": "https://brandinavian.com/#website",
        url: "https://brandinavian.com/",
        name: "Brandinavian",
        description: "USA Web Developer",
        publisher: { "@id": "https://brandinavian.com/#organization" },
        inLanguage: "en-US",
      },
      {
        "@type": "WebPage",
        "@id": "https://brandinavian.com/about/#webpage",
        url: "https://brandinavian.com/about/",
        name: "SEO | Brandinavian",
        isPartOf: { "@id": "https://brandinavian.com/#website" },
        description:"I help businesses build best-in-class, scalable web applications, that convert and communicate well. Get started with Brandinavian today!",
        breadcrumb: {
          "@id":
            "https://brandinavian.com/about/#breadcrumb",
        },
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://brandinavian.com/about"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id":
          "https://brandinavian.com/about/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/",
              url: "https://brandinavian.com/",
              name: "Home",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/about",
              url: "https://brandinavian.com/about",
              name: "About",
            },
          },
        ],
      },
    ],
  };
  return (
    <Layout>
      <SEO title="About | Brandinavian" schemaMarkup={schema} lang="en-US" />

      <BannerAbout />

      <div id="main">
      <ExplainerAbout />
      <ExplainerAlt />
        
        
      </div>
    </Layout>
  );
};
export default LandingSEO;

export const seoQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "seo-sword.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "spaceman.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "med-kit.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
