import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import Img from "gatsby-image";

class ExplainerAbout extends React.Component {

  render() {
    return (
      <StaticQuery
        query={graphql`
  query {
    imageOne: file(relativePath: { eq: "astronaut.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
        } 
      }
    }
}
`}
        render={data => (
    <section id="explainer-section">
    <div className="inner special explainer">
    <div className="about-box">
    <div className="about-image">
    <Img fluid={data.imageOne.childImageSharp.fluid} alt="" />
    </div>
    <div className="about-explainer">
        <header className="major special">
            <h2>Aiming for the stars</h2>
        </header>
        <p className="displayText">My name is Mads. Through Brandinavian, I work with companies and organizations in a broad range of verticals, designing and engineering digital solutions with cutting edge technologies. I have helped organizations and companies in the USA, Europe and in Asia. I have a solid educational foundation, I hold a <b>Master's degree</b> in Media Science, focused on ethical dilemmas in industrial-scale data accumulation. I also hold a technical design degree.<br/><br/><b>I  build best-in-class, scalable web applications, that convert and communicate well.</b></p>
        <ul className="actions lowkey">
            <li><Link to="/contact" className="button">Request Resume</Link></li>
        </ul>

        </div>
        </div>
    </div>
</section>
        )}
      />
    );
  }
}

export default ExplainerAbout;